/* body {
    min-width: unset;
} */

.mob-only {
    display: none;
}

.menu-toggle {
    position: relative;
    width: 33px;
    height: 23px;
    margin-right: 32px;
    cursor: pointer;
}

.menu-toggle.active .menu-toggle--line {
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
}

.menu-toggle.active .fst {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 10px;
}

.menu-toggle.active .sec {
    opacity: 0;
}

.menu-toggle.active .trd {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 10px;
}

.menu-toggle--line {
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    background-color: #fff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.menu-toggle--line.fst {
    top: 0;
}

.menu-toggle--line.sec {
    top: 9px;
}

.menu-toggle--line.trd {
    top: 18px;
}

.icon-button {
    color: black;
    font-size: 16px;
    padding-left: 2ch;
}

.diagram > div:last-child {
    padding-bottom: 20px;
}

.diagram button {
    border-radius: 5px;
}

.diagram button:hover {
    background-color: transparent;
}

.diagram button:focus {
    outline: unset;
}

.product-table,
.whitesided-table {
    position: relative;
    isolation: isolate;
    overflow-y: hidden;
    overflow-x: hidden;
}

.whitesided-table .row {
    margin: 0;
}

.whitesided-table > div {
    overflow-y: auto;
}

.product-table::before,
.whitesided-table::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    bottom: -100px;
    box-shadow: inset 0px 0px 5px #FFF, inset 0px 0px 10px #FFF, inset 0px 0px 15px #FFF;
    pointer-events: none;
    z-index: +1;
}

.product-table tr td:nth-child(3) {
    min-width: 15ch;
}

@media screen and (max-width: 1200px) {
    /*bootstrap Xl sizes*/
    .mob-only {
        display: block;
    }

    header.header {
        height: auto;
        max-height: 100vh;
        position: sticky;
        top: 0;
        z-index: 800;
        transition: max-height 250ms linear;
        padding: 0 1ch;
    }

    header .container .row .col-12 .flex.align-items-center {
        display: grid;
        grid-template-areas: "mob-menu logo logo logo spacer" "search search search search search" "lang-switch lang-switch spacer2 account account";
        grid-template-columns: 50px 1fr 1fr 1fr 70px;
        grid-gap: 0.25ch;
    }

    header .container .row .col-12 .flex > div {
        max-width: unset;
        padding: 0;
    }

    header .container .row .col-12 .flex .mob-menu.mobile-hidden {
        grid-area: mob-menu;
    }

    header .container .row .col-12 .flex .logo {
        grid-area: logo;
    }

    header .container .row .col-12 .flex .logo a {
        margin: auto;
    }

    header .container .row .col-12 .flex .logo::after {
        content: unset;
    }

    header .container .row .col-12 .flex .logo + div {
        grid-area: spacer;
    }

    header .container .row .col-12 .flex .search {
        grid-area: search;
    }

    header .container .row .col-12 .flex .search:focus-within ~ div {
        z-index: -1000;
    }

    header .container .row .col-12 .flex .search .row {
        justify-content: center;
    }

    header .container .row .col-12 .flex .search + div {
        grid-area: spacer2;
    }

    header .container .row .col-12 .flex .lang-switch {
        grid-area: lang-switch;
        place-self: center start;
    }

    header .container .row .col-12 .flex .lang-switch a {
        margin-left: 0;
        margin-right: 15px;
    }

    header .container .row .col-12 .flex .account {
        grid-area: account;
        place-self: center end;
    }

    header .container .row .col-12 .flex .account a {
        margin-left: 15px;
        margin-right: 0;
    }

    header .account .row {
        margin-right: 0;
    }

    .header-toggle {
        place-self: center right;
        height: 33px;
        width: 70px;
        margin-left: auto;
        background-image: url(/public/images/magnifying-glass-solid.svg), url(/public/images/circle-user-regular.svg);
        background-position: center left, center right;
        background-repeat: no-repeat, no-repeat;
        background-size: contain, contain;
        cursor: pointer;
    }

    .mob-secondary {
        --speed: 150ms;
        transform-origin: top center;
        transform: rotateX(90deg) scaleZ(0);
        transition: transform var(--speed) linear, margin var(--speed) linear, max-height var(--speed) linear;
        max-height: 1px;
        /* margin: 0; */
    }

    .mob-secondary.shown {
        margin-top: 1ch;
        margin-bottom: 1ch;
        transform: rotateX(0deg) scaleZ(1);
        max-height: 50px;
    }

    aside.sidebar {
        position: fixed;
        left: 0;
        top: 5em;
        display: grid;
        grid-auto-flow: row;
        transform-origin: center left;
        visibility: hidden;
        transform: rotateY(90deg);
        transition: transform 250ms linear, visibility 251ms linear 0ms;
        z-index: +1;
        backdrop-filter: blur(4px);
        background-color: #FFFE;
        padding-right: 0;
        width: 400px;
        max-width: 100%;
        max-height: calc(95% - 5em);
        max-height: calc(100svh - 5em);
        overflow-y: auto;
        isolation: isolate;
        gap: 1ch;
        padding: 1ch;
        z-index: +10000;
    }

    aside.sidebar.shown {
        visibility: visible;
        transform: rotateY(0deg);
        transition: transform 250ms linear, visibility 0ms linear 0ms,
    }

    .sidebar-title {
        margin-right: 56px;
    }

    .mob-menu {
        width: 33px;
        height: 23px;
        margin-right: 32px;
        cursor: pointer;
    }
}

@media screen and (max-width: 440px) {
    header .container .row .col-12 .flex.align-items-center {
        display: grid;
        grid-template-areas: "mob-menu logo spacer" "search search search" "lang-switch lang-switch lang-switch" "account account account";
        grid-template-columns: 50px 2fr 70px;
        grid-gap: 0.25ch;
    }

    header .container .row .col-12 .flex .search + div {
        display: none;
    }
}
