.ant-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding-bottom: 24px;
}

.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
    transform: none;
    /*opacity: 0;*/
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
    display: none;
}

.ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
    z-index: 1000;
}

.ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
}

.ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
}

.ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}

.ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}

.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 8px;
}

.ant-modal-open {
    overflow: hidden;
}

.ant-modal-centered {
    text-align: center;
}

.ant-modal-centered::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
}

.ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: left;
    vertical-align: middle;
}

@media (max-width: 767px) {
    .ant-modal {
        max-width: calc(100vw - 16px);
        margin: 8px auto;
    }

    .ant-modal-centered .ant-modal {
        flex: 1;
    }
}

.ant-modal-confirm .ant-modal-header {
    display: none;
}

.ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper::before {
    display: table;
    content: '';
}

.ant-modal-confirm-body-wrapper::after {
    display: table;
    clear: both;
    content: '';
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
    float: left;
    margin-right: 16px;
    font-size: 22px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-bottom: 0;
    margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #52c41a;
}

.ant-modal-wrap-rtl {
    direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
    right: initial;
    left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
    text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
    margin-right: 8px;
    margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
    direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
    float: right;
    margin-right: 0;
    margin-left: 16px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-right: 38px;
    margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
    float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-right: 8px;
    margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
    text-align: right;
}

.ant-slider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    height: 12px;
    margin: 10px 6px 10px;
    padding: 4px 0;
    cursor: pointer;
    touch-action: none;
}

.ant-slider-vertical {
    width: 12px;
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px;
}

.ant-slider-vertical .ant-slider-rail {
    width: 4px;
    height: 100%;
}

.ant-slider-vertical .ant-slider-track {
    width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
    margin-top: -6px;
    margin-left: -5px;
}

.ant-slider-vertical .ant-slider-mark {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%;
}

.ant-slider-vertical .ant-slider-mark-text {
    left: 4px;
    white-space: nowrap;
}

.ant-slider-vertical .ant-slider-step {
    width: 4px;
    height: 100%;
}

.ant-slider-vertical .ant-slider-dot {
    top: auto;
    margin-left: -2px;
}

.ant-slider-tooltip .ant-tooltip-inner {
    min-width: unset;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
    margin-right: -5px;
    margin-left: 0;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
    right: 12px;
    left: auto;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
    right: 4px;
    left: auto;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
    right: 2px;
    left: auto;
}

.ant-slider-with-marks {
    margin-bottom: 28px;
}

.ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f5f5f5;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: #91d5ff;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #91d5ff;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider-handle-dragging {
    z-index: 1;
}

.ant-slider-handle:focus {
    border-color: #46a6ff;
    outline: none;
    box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}

.ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff;
}

.ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1;
}

.ant-slider:hover .ant-slider-track {
    background-color: #69c0ff;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
}

.ant-slider-mark {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    font-size: 14px;
}

.ant-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    word-break: keep-all;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.85);
}

.ant-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
}

.ant-slider-dot {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    cursor: pointer;
}

.ant-slider-dot-active {
    border-color: #8cc8ff;
}

.ant-slider-disabled {
    cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-rail {
    background-color: #f5f5f5 !important;
}

.ant-slider-disabled .ant-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: none;
    cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
    cursor: not-allowed !important;
}

.ant-slider-rtl {
    direction: rtl;
}

.ant-slider-rtl .ant-slider-mark {
    right: 0;
    left: auto;
}

.ant-tooltip {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    z-index: 1070;
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    width: intrinsic;
    max-width: 250px;
    visibility: visible;
}

.ant-tooltip-hidden {
    display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
    padding-bottom: 14.3137085px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
    padding-left: 14.3137085px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
    padding-top: 14.3137085px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
    padding-right: 14.3137085px;
}

.ant-tooltip-inner {
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tooltip-arrow {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
}

.ant-tooltip-arrow-content {
    --antd-arrow-background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 11.3137085px;
    height: 11.3137085px;
    margin: auto;
    background-color: transparent;
    content: '';
    pointer-events: auto;
    border-radius: 0 0 2px 0;
    pointer-events: none;
}

.ant-tooltip-arrow-content::before {
    position: absolute;
    top: -11.3137085px;
    left: -11.3137085px;
    width: 33.9411255px;
    height: 33.9411255px;
    background: linear-gradient(to left, var(--antd-arrow-background-color) 50%, var(--antd-arrow-background-color) 50%) no-repeat -10px -10px;
    content: '';
    -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 Z');
    clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 Z');
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: -7.4462915px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(-11px) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    left: -7.5862915px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(11px) rotate(135deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    right: -7.6062915px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateX(-11px) rotate(315deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: -7.5862915px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    transform: translateY(11px) rotate(225deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 13px;
}

.ant-tooltip-pink .ant-tooltip-inner {
    background-color: #eb2f96;
}

.ant-tooltip-pink .ant-tooltip-arrow-content::before {
    background: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-inner {
    background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
    background: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner {
    background-color: #f5222d;
}

.ant-tooltip-red .ant-tooltip-arrow-content::before {
    background: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner {
    background-color: #fa541c;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
    background: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner {
    background-color: #fa8c16;
}

.ant-tooltip-orange .ant-tooltip-arrow-content::before {
    background: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner {
    background-color: #fadb14;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
    background: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner {
    background-color: #faad14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content::before {
    background: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner {
    background-color: #13c2c2;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
    background: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner {
    background-color: #a0d911;
}

.ant-tooltip-lime .ant-tooltip-arrow-content::before {
    background: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner {
    background-color: #52c41a;
}

.ant-tooltip-green .ant-tooltip-arrow-content::before {
    background: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner {
    background-color: #1890ff;
}

.ant-tooltip-blue .ant-tooltip-arrow-content::before {
    background: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner {
    background-color: #2f54eb;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
    background: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner {
    background-color: #722ed1;
}

.ant-tooltip-purple .ant-tooltip-arrow-content::before {
    background: #722ed1;
}

.ant-tooltip-rtl {
    direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
    text-align: right;
}

.ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
}

.ant-slider-tooltip .ant-tooltip-inner {
    min-width: unset;
}